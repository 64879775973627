import { createMongoAbility } from '@casl/ability'

import Api from "@/axios"

const ability = createMongoAbility([{ action: 'read', subject: 'guest'}]);

function updateAbility(value) {
    var rules = [
        { action: 'read', subject: 'guest'}, 
        { action: ['read', 'post'], subject: 'auth' }
    ];
    if (value.status == 'pending') {
        rules.push({ action: 'read', subject: 'pending'})
    }else{
        value.type.access.forEach(e => {
            rules.push(e)
        });
    }
    ability.update(rules);
}

export const userLogin = await Api.get('/auth')

if (userLogin.data) {
    updateAbility(userLogin.data);
}

export default ability;
