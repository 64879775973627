<template>
    <VBtn @click="changeTheme" icon>
        <VIcon color="primary">
            <span :class="icon"></span>
        </VIcon>
        <VTooltip activator="parent" open-delay="1000" scroll-strategy="close">
            <span class="text-capitalize">{{ theme.global.name.value }}</span>
        </VTooltip>
    </VBtn>
</template>

<script setup>
import { useTheme } from "vuetify";
import { computed } from "vue";

const theme = useTheme();
// console.log(theme.global.name.value);
const icon = computed(() => {
    return theme.global.current.value.dark
        ? "icon-[ic--round-light-mode]"
        : "icon-[ic--twotone-dark-mode]";
});

const changeTheme = () => {
    theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
    localStorage.setItem(
        "themeVariant",
        JSON.stringify(theme.global.name.value)
    );
    if (theme.global.name.value == "dark") {
        document.body.classList.add("dark");
    } else {
        document.body.classList.remove("dark");
    }
};
</script>
