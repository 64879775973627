<template>
    <v-app class="overflow-hidden" id="layout">
        <v-defaults-provider :defaults="defaultProvider">
            <!-- start v-navigation-drawer -->
            <v-navigation-drawer
            v-if="!$can('read', 'manajemen')"
                v-model="drawer"
                location="left"
                flat
                :width="$vuetify.display.smAndUp ? 330 : 600"
                class="py-5 custom-nav"
            >
                <div class="h-full d-flex flex-col justify-between">
                    <!-- :style=" drawer && $vuetify.display.smAndDown ? 'bottom: 64px;' : 'bottom: 0px' " -->
                    <div class="pl-5 pr-5">
                        <v-list>
                            <v-list-item class="px-2">
                                <div class="flex align-center justify-between">
                                    <div class="flex align-center gap-3 cursor-pointer" @click="goHome">
                                        <v-avatar
                                            rounded="lg"
                                            size="50"
                                            class="mr-3"
                                        >
                                            <v-img
                                                :src="
                                                    asset('/logo-with-bg.png')
                                                "
                                            ></v-img>
                                        </v-avatar>
                                        <div class="text-xl font-bold">
                                            <div>Mentari</div>
                                        </div>
                                    </div>
                                    <v-btn
                                        icon
                                        rounded="full"
                                        variant="text"
                                        v-if="$vuetify.display.mdAndDown"
                                    >
                                        <v-icon
                                            class="icon-[ic--baseline-close]"
                                            @click="drawer = false"
                                        ></v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item>
                        </v-list>
                        <v-divider class="mb-0"></v-divider>
                    </div>

                    <!-- menu component -->
                    <menu-list></menu-list>

                    <v-spacer></v-spacer>
                    <!-- <div style="height: 15px"></div> -->
                    <div class="pl-5 pr-5">
                        <v-divider class="mt-0 mb-4"</v-divider>
                        <!-- <v-list-item
                            class="mb-5"
                            height="50"
                            elevation="0"
                            rounded="lg"
                            @click="goHome"
                        >
                            <template #prepend>
                                <v-icon
                                    class="icon-[tabler--arrow-left]"
                                    size="29"
                                ></v-icon>
                            </template>
                            <template #append>
                                <v-icon
                                    class="icon-[tabler--home]"
                                    size="29"
                                ></v-icon>
                            </template>
                            <p class="font-medium">Home</p>
                        </v-list-item> -->
                        <app-bar-user-menu :sidebar="true"></app-bar-user-menu>
                    </div>
                </div>
            </v-navigation-drawer>
            <!-- end v-navigation-drawer -->

            <!-- app bar top -->
            <!-- banner offline -->
            <v-app-bar v-if="offline" style="z-index: 1006">
                <v-banner :sticky="true" lines="two" height="fit-content">
                    <template v-slot:prepend>
                        <v-avatar color="red">
                            <v-icon class="icon-[tabler--wifi-off]"></v-icon>
                        </v-avatar>
                    </template>
                    <template v-slot:text>
                        Oops, anda offline. periksa koneksi internet anda.
                    </template>
                </v-banner>
            </v-app-bar>
            <v-overlay v-model="offline" persistent z-index="1005"></v-overlay>
            <!-- scroll app bar -->
            <!-- <v-app-bar
                v-if="$vuetify.display.mdAndDown"
                app
                flat
                class="drop-shadow-xl"
                :class="{
                    '!border-b-[1px] !border-[#2e3c34]':
                        $vuetify.theme.current.dark,
                }"
                :height="80"
                style="z-index: 100"
                scroll-behavior="hide inverted"
                scroll-threshold="280"
            >
                <navigation-bar
                    @emitHome="goHome"
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar> -->
            <!-- color="#52bc94" -->
            <v-app-bar
                v-if="$vuetify.display.mdAndDown || $can('read', 'manajemen')"
                floating
                flat
                class="drop-shadow-xl"
                :height="80"
            >
                <navigation-bar
                    @emitHome="goHome"
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar>

            <v-main id="main" class="layout-page-content">
                <router-view v-slot="{ Component }">
                    <v-slide-y-transition mode="out-in">
                        <component :is="Component" />
                    </v-slide-y-transition>

                    <!-- <transition name="page">
                        <component :is="Component" />
                    </transition> -->
                </router-view>
                <data class="h-20"></data>
            </v-main>
        </v-defaults-provider>
    </v-app>
</template>

<script setup>
import { asset, replaceAvaByDefault } from "@/mixins";
// import MenuList from "./menu/MenuList.vue";

import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import NavigationBar from "./NavigationBar.vue";
import BottomAppBar from "./component/BottomAppBar.vue";
import {
    ref,
    onMounted,
    computed,
    provide,
    reactive,
    onBeforeUnmount,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ability from "@/plugins/casl/ability";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

import { useRouter } from "vue-router";
const router = useRouter();

const theme = useTheme();
const { xs, lgAndUp, width } = useDisplay();
const isDark = computed(() => {
    let current = theme.current.value;
    return current.dark;
});

function goHome() {
    if (width.value < 1280) {
        drawer.value = false;
        router.push({ name: "dashboard" });
    } else {
        router.push({ name: "dashboard" });
    }
}

// const countMenu = computed(() => {
//     return menu.filter((f) => {
//         return ability.can("read", f.access);
//     }).length;
// });
const isDisplay = computed(() => {
    return xs;
});

provide("$isDark", isDark);

const defaultProvider = reactive({
    VCard: {
        border: isDark,
    },
    VToolbar: {
        border: isDark,
    },
    VDialogFull: {
        fullscreen: xs,
        persistent: true,
        scrollable: true,
    },
});

const drawer = ref(lgAndUp);
const offsetTop = ref(0);

const handleDrawer = () => {
    drawer.value = !drawer.value;
};

const onScroll = () => {
    offsetTop.value = window.top.scrollY;
};

const offline = ref(false);
function handleConnectionChange() {
    offline.value = !navigator.onLine;
}

const banner = ref(false);
provide("$banner", banner);
let deferredPrompt = ref(window._deferredPrompt);
provide("$deferredPrompt", deferredPrompt);

async function installApp() {
    banner.value = false;
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === "accepted") {
            deferredPrompt.value = null;
        } else {
            deferredPrompt.value = window._deferredPrompt;
        }
    }
}

function installLater() {
    localStorage.setItem("installLater", JSON.stringify(true));
    banner.value = false;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("online", handleConnectionChange);
});

// app.config.globalProperties.$offsetTop = offsetTop.value;
</script>

<style scoped>
/* .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: 1400px;
    width: 100vw;
} */

.layout-page-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: fit-content;
}

.footer .content {
    /* height: 300px; */
    padding-left: 7%;
    padding-right: 7%;
}

.accelerate {
    transform: translateZ(0) !important;
    transform: translate3d(0, 0, 0) !important;
    will-change: transform, opacity;
}
</style>
