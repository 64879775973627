export default [
    {
        path: "/master-data/question-list",
        name: "soalTesKejiwaan",
        component: () =>
            import("@/pages/masterdata/soal-tes-kejiwaan/SoalTesKejiwaan.vue"),
        meta: {
            pageTitle: "Soal Tes kejiwaan",
            icon: "icon-[tabler--message-question]",
            layout: "LayoutUser",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/trankasi",
        name: "transaction",
        component: () =>
            import("@/pages/masterdata/transaction/Transaction.vue"),
        meta: {
            pageTitle: "Transaksi",
            icon: "icon-[mdi--patient]",
            layout: "LayoutUser",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/doctor-list",
        name: "doctorList",
        component: () =>
            import("@/pages/masterdata/doctor-list/DoctorList.vue"),
        meta: {
            pageTitle: "Praktisi Kesehatan",
            icon: "icon-[mdi--doctor]",
            layout: "LayoutUser",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/schedule",
        name: "schedule",
        component: () => import("@/pages/masterdata/schedule/Schedule.vue"),
        meta: {
            pageTitle: "Jadwal Dokter",
            icon: "icon-[mdi--clipboard-text-date-outline]",
            layout: "LayoutUser",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/artikel-list",
        name: "artikelList",
        component: () =>
            import("@/pages/masterdata/artikel-list/ArtikelList.vue"),
        meta: {
            pageTitle: "List Artikel",
            icon: "icon-[mdi--newspaper-variant-multiple-outline]",
            layout: "LayoutUser",
            resource: "admin",
            action: "read",
        },
    },
    {
        path: "/master-data/admins",
        name: "admins",
        component: () => import("@/pages/masterdata/users/User.vue"),
        meta: {
            pageTitle: "Admin",
            icon: "icon-[ic--twotone-manage-accounts]",
            layout: "LayoutFull",
            resource: "all",
            action: "read",
        },
    },
];
