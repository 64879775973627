export default [
    {
        path: "/konsult-online",
        name: "konsultOnline",
        component: () => import("@/pages/konsult-online/KonsultOnline.vue"),
        meta: {
            pageTitle: "Konsultasi Online",
            icon: "icon-[ph--chalkboard-teacher-duotone]",
            layout: "LayoutUser",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/artikel/:ulid",
        name: "artikelDetail",
        component: () => import("@/pages/artikel/ArtikelDetail.vue"),
        meta: {
            pageTitle: "Detail Artikel",
            icon: "icon-[ph--chalkboard-teacher-duotone]",
            layout: "LayoutUser",
            resource: "guest",
            action: "read",
        },
    },
];
