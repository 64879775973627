<template>
    <v-app class="overflow-hidden" id="layout">
        <v-defaults-provider :defaults="defaultProvider">
            <!-- start v-navigation-drawer -->
            <v-navigation-drawer
                v-model="drawer"
                temporary
                location="right"
                flat
                touchless
            >
                <!-- :style=" drawer && $vuetify.display.smAndDown ? 'bottom: 64px;' : 'bottom: 0px' " -->
                <v-list>
                    <v-list-item title="Mentari" subtitle="RSUD Cibinong">
                        <template #prepend>
                            <v-avatar
                                color="white"
                                size="45"
                                rounded
                                class="p-1"
                            >
                                <v-img
                                    :src="asset('android-chrome-512x512.png')"
                                    height="100%"
                                />
                            </v-avatar>
                        </template>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <template v-for="m in menu">
                        <v-list-item
                            v-if="m.type == 'btn' && $can('read', m.access)"
                            :title="m.title"
                            :to="{ name: m.name }"
                        >
                            <template #prepend>
                                <v-icon :class="m.icon"></v-icon>
                            </template>
                        </v-list-item>
                        <v-list-group
                            v-if="
                                m.type == 'dropdown' && $can('read', m.access)
                            "
                            :value="m.title"
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" :title="m.title">
                                    <template #prepend>
                                        <v-icon :class="m.icon"></v-icon>
                                    </template>
                                </v-list-item>
                            </template>
                            <template v-for="l in m.list">
                                <v-list-item
                                    v-if="$can('read', l.access)"
                                    :title="l.title"
                                    :to="{ name: l.name }"
                                    link
                                >
                                    <template #prepend>
                                        <v-icon :class="l.icon"></v-icon>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-group>
                    </template>
                    <div style="height: 65px"></div>
                </v-list>
            </v-navigation-drawer>
            <!-- end v-navigation-drawer -->

            <!-- app bar top -->
            <!-- banner offline -->
            <v-app-bar v-if="offline" style="z-index: 1006">
                <v-banner :sticky="true" lines="two" height="fit-content">
                    <template v-slot:prepend>
                        <v-avatar color="red">
                            <v-icon class="icon-[tabler--wifi-off]"></v-icon>
                        </v-avatar>
                    </template>
                    <template v-slot:text>
                        Oops, anda offline. periksa koneksi internet anda.
                    </template>
                </v-banner>
            </v-app-bar>
            <v-overlay v-model="offline" persistent z-index="1005"></v-overlay>
            <!-- scroll app bar -->
            <!-- <v-app-bar
                aspect-ratio="1"
                app
                flat
                class="drop-shadow-xl"
                :class="{
                    '!border-b-[1px] !border-[#2e3c34]':
                        $vuetify.theme.current.dark,
                }"
                :height="$vuetify.display.mdAndUp ? 100 : 80"
                style="z-index: 100"
                scroll-behavior="hide inverted"
                scroll-threshold="280"
            >
                <navigation-bar
                    :countMenu
                    :menu
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar> -->

            <!-- color="#52bc94" -->
            <v-app-bar
                aspect-ratio="1"
                floating
                location="top"
                flat
                class="drop-shadow-xl"
                style="border-bottom: 5px solid #fec20e"
                :height="$vuetify.display.mdAndUp ? 100 : 80"
            >
                <navigation-bar
                    :countMenu
                    :menu
                    @emitDrawer="handleDrawer"
                    :drawer="drawer"
                ></navigation-bar>
            </v-app-bar>

            <v-main id="main" class="layout-page-content">
                <router-view v-slot="{ Component }">
                    <transition name="page">
                        <component :is="Component" />
                    </transition>
                </router-view>
                <div
                    :style="
                        $vuetify.display.mdAndUp
                            ? 'height: 300px'
                            : 'height: 300px'
                    "
                ></div>
                <!-- footer -->
                <!-- v-if="$vuetify.display.lgAndUp" -->
                <!-- <v-fab
                    app
                    style="bottom: 30px; left: 20px"
                    color="green"
                    location="bottom start"
                    size="large"
                    icon
                    rounded="full"
                    href="https://wa.me/62811128347"
                >
                    <v-icon
                        class="icon-[tabler--brand-whatsapp]"
                        size="40"
                    ></v-icon>
                </v-fab> -->

                <v-sheet
                    color="#251c00"
                    class="footer card-full mb-0 py-10 px-[10%]"
                >
                    <div class="flex align-center">
                        <v-avatar color="" size="60" rounded>
                            <v-img :src="asset('android-chrome-512x512.png')" />
                        </v-avatar>
                        <div class="ml-1" v-if="$vuetify.display.width > 350">
                            <div class="font-bold text-xl sm:text-2xl">
                                Mentari
                            </div>
                            <div class="text-[9pt] sm:text-sm -mt-1">
                                RSUD Cibinong
                            </div>
                        </div>
                    </div>
                    <hr class="my-6" />
                    <div
                        class="flex gap-6 justify-between align-center flex-col md:flex-row mb-5"
                    >
                        <div
                            class="order-1 md:order-2 text-center text-md-start text-gray-400"
                        >
                            Copyright © 2024
                            <br />
                            IT System Development - RSUD Cibinong
                        </div>
                        <!-- <div>
                            <v-btn
                                href="https://www.instagram.com/mentari.rsudcibinong/"
                                target="_blank"
                                size="small"
                                icon
                                variant="text"
                                color="white"
                                rounded="full"
                                class="mr-3"
                            >
                                <v-icon
                                    size="30"
                                    class="icon-[tabler--brand-instagram]"
                                />
                            </v-btn>
                            <v-btn
                                size="small"
                                icon
                                variant="text"
                                color="white"
                                rounded="full"
                                class="mr-3"
                            >
                                <v-icon
                                    class="icon-[tabler--brand-facebook]"
                                    size="30"
                                />
                            </v-btn>
                            <v-btn
                                size="small"
                                icon
                                variant="text"
                                color="white"
                                rounded="full"
                                class="mr-3"
                            >
                                <v-icon
                                    class="icon-[tabler--brand-twitter]"
                                    size="30"
                                />
                            </v-btn>
                            <v-btn
                                size="small"
                                icon
                                variant="text"
                                color="white"
                                rounded="full"
                                class="mr-3"
                            >
                                <v-icon
                                    class="icon-[tabler--brand-youtube]"
                                    size="30"
                                />
                            </v-btn>
                            <v-btn
                                size="small"
                                icon
                                variant="text"
                                color="white"
                                rounded="full"
                            >
                                <v-icon
                                    class="icon-[tabler--brand-tiktok]"
                                    size="30"
                                />
                            </v-btn>
                        </div> -->
                    </div>
                </v-sheet>
            </v-main>
        </v-defaults-provider>
    </v-app>
</template>

<script setup>
import { asset, replaceAvaByDefault } from "@/mixins";
import menu from "@/menu";
import NavigationBar from "./NavigationBar.vue";
import BottomAppBar from "./component/BottomAppBar.vue";
import {
    ref,
    onMounted,
    computed,
    provide,
    reactive,
    onBeforeUnmount,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ability from "@/plugins/casl/ability";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

const theme = useTheme();
const { xs } = useDisplay();
const isDark = computed(() => {
    let current = theme.current.value;
    return current.dark;
});

const countMenu = computed(() => {
    return menu.filter((f) => {
        return ability.can("read", f.access);
    }).length;
});
const isDisplay = computed(() => {
    return xs;
});

provide("$isDark", isDark);

const defaultProvider = reactive({
    VCard: {
        border: isDark,
    },
    VToolbar: {
        border: isDark,
    },
    VDialogFull: {
        fullscreen: xs,
        persistent: true,
        scrollable: true,
    },
});

const drawer = ref(false);
const offsetTop = ref(0);

const handleDrawer = () => {
    drawer.value = !drawer.value;
};

const onScroll = () => {
    offsetTop.value = window.top.scrollY;
};

const offline = ref(false);
function handleConnectionChange() {
    offline.value = !navigator.onLine;
}

const banner = ref(false);
provide("$banner", banner);
let deferredPrompt = ref(window._deferredPrompt);
provide("$deferredPrompt", deferredPrompt);

async function installApp() {
    banner.value = false;
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === "accepted") {
            deferredPrompt.value = null;
        } else {
            deferredPrompt.value = window._deferredPrompt;
        }
    }
}

function installLater() {
    localStorage.setItem("installLater", JSON.stringify(true));
    banner.value = false;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);
    let install = JSON.parse(localStorage.getItem("installLater"));
    if (
        !install &&
        !authStore.isIos &&
        !window.matchMedia("(display-mode: standalone)").matches
    ) {
        setTimeout(() => {
            banner.value = true;
        }, 10000);
    }
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("online", handleConnectionChange);
});

// app.config.globalProperties.$offsetTop = offsetTop.value;
</script>

<style scoped>
/* .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: 1400px;
    width: 100vw;
} */

.layout-page-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: fit-content;
}

.footer .content {
    /* height: 300px; */
    padding-left: 7%;
    padding-right: 7%;
}

.accelerate {
    transform: translateZ(0) !important;
    transform: translate3d(0, 0, 0) !important;
    will-change: transform, opacity;
}
</style>
