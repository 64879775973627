<template>
    <div
        class="px-3 py-2 bg-menu flex gap-2"
        v-if="$vuetify.display.width > 1160"
    >
        <template v-for="m in menu">
            <!-- color="white" -->
            <v-btn
                v-if="m.type == 'btn' && $can('read', m.access)"
                color=""
                rounded
                :variant="$route.name != m.name ? 'text' : 'outlined'"
                :to="{ name: m.name }"
                class="text-capitalize"
            >
                <v-icon start>
                    <span :class="m.icon"></span>
                </v-icon>
                <div class="tracking-norma">
                    {{ m.title }}
                </div>
            </v-btn>
            <v-menu
                v-if="m.type == 'dropdown' && $can('read', m.access)"
                transition="slide-y-transition"
                position="bottom"
                offset="10"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        :variant="checkIfActive(m.list) ? 'outlined' : 'text'"
                        text
                        v-bind="props"
                        color=""
                        rounded
                        class="text-capitalize"
                    >
                        <v-icon start :class="m.icon"></v-icon>
                        <div class="tracking-normal">
                            {{ m.title }}
                        </div>
                        <v-icon
                            end
                            class="icon-[tabler--chevron-down]"
                        ></v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <template v-for="l in m.list">
                        <v-list-item
                            link
                            :to="{ name: l.name }"
                            v-if="$can('read', l.access)"
                        >
                            <template v-slot:prepend>
                                <v-icon :class="l.icon"></v-icon>
                            </template>
                            <v-list-item-title>
                                {{ l.title }}</v-list-item-title
                            >
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>
<script setup>
const props = defineProps(["menu"]);
import { useRoute } from "vue-router";
const route = useRoute();

function checkIfActive(list) {
    let find = list.find((e) => e.name == route.name);
    return find ? true : false;
}
</script>

<!-- <style lang="scss" scoped>
.bg-menu {
    background-color: #fbf5ff15;
    border-radius: 40px;
}
</style> -->
