export default [
    {
        path: '/',
        redirect: 'home',
    },
    {
        path: '/:pathMatch(.*)*',
        // redirect: 'error-404',
        redirect: 'home',
    },
]
