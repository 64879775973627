// firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCdx8bLTsFa9sbDfdQPmBfzT2EOqbtbcls",
    authDomain: "rs-pendidikan.firebaseapp.com",
    projectId: "rs-pendidikan",
    storageBucket: "rs-pendidikan.appspot.com",
    messagingSenderId: "61406711033",
    appId: "1:61406711033:web:a062ff1dc4eae281f72628",
    measurementId: "G-F016Y2MTP5",
};

// Initialize Firebase
const fireApp = initializeApp(firebaseConfig);
const analytics = () => {
    getAnalytics(fireApp);
};
// const messaging = getMessaging(fireApp);

export { analytics };
