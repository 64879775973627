export default [
    {
        path: "/artikel",
        name: "artikel",
        component: () => import("@/pages/artikel/Artikel.vue"),
        meta: {
            pageTitle: "Artikel",
            icon: "icon-[tabler--news]",
            layout: "LayoutUser",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/artikel/:ulid",
        name: "artikelDetail",
        component: () => import("@/pages/artikel/ArtikelDetail.vue"),
        meta: {
            pageTitle: "Detail Artikel",
            icon: "icon-[tabler--news]",
            layout: "LayoutUser",
            resource: "guest",
            action: "read",
        },
    },
];
