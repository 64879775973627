<template>
    <LayoutUser v-if="route.meta.layout == 'LayoutUser'" />
    <LayoutAdmin v-if="route.meta.layout == 'LayoutAdmin'" />
    <LayoutFull v-if="route.meta.layout == 'LayoutFull'" />
    <LayoutBlank v-if="route.meta.layout == 'LayoutBlank'" />
</template>

<script setup>
import { useRoute } from "vue-router";
import LayoutAdmin from "./layout/LayoutAdmin.vue";
import LayoutFull from "./layout/LayoutFull.vue";
import LayoutUser from "./layout/LayoutUser.vue";
import LayoutBlank from "./layout/LayoutBlank.vue";

const route = useRoute();
</script>
