const menu = [
    {
        name: "home",
        icon: "icon-[tabler--home]",
        title: "Beranda",
        access: "guest",
        type: "btn",
    },
    {
        name: "konsultOnline",
        icon: "icon-[ph--chalkboard-teacher-duotone]",
        title: "Konsultasi Online",
        access: "pasien",
        type: "btn",
    },
    {
        name: "artikel",
        icon: "icon-[tabler--news]",
        title: "Artikel",
        access: "guest",
        type: "btn",
    },
    {
        name: "hasilTesKejiwaan",
        icon: "icon-[tabler--message-question]",
        title: "Hasil Tes",
        access: "admin",
        type: "btn",
    },
    // {
    //     name: "gallery",
    //     icon: "icon-[tabler--photo]",
    //     title: "Galeri",
    //     access: "guest",
    //     type: "btn",
    // },
    {
        icon: "icon-[tabler--database]",
        title: "Master",
        access: "admin",
        type: "dropdown",
        list: [
            {
                name: "transaction",
                icon: "icon-[mdi--patient]",
                title: "Transaksi",
                access: "admin",
            },
            {
                name: "doctorList",
                icon: "icon-[mdi--doctor]",
                title: "Praktisi Kesehatan",
                access: "admin",
            },
            {
                name: "artikelList",
                icon: "icon-[mdi--newspaper-variant-multiple-outline]",
                title: "Daftar Artikel",
                access: "admin",
            },
            {
                name: "schedule",
                icon: "icon-[mdi--clipboard-text-date-outline]",
                title: "Jadwal Dokter",
                access: "admin",
            },
            // {
            //     name: "soalTesKejiwaan",
            //     icon: "icon-[tabler--message-question]",
            //     title: "Test Kejiwaan",
            //     access: "admin",
            // },
        ],
    },
];

export default menu;
