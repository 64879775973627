import "vuetify/styles";
import { createVuetify } from "vuetify";
import { VCard, VDivider, VIcon, VDialog } from "vuetify/components";
// import * as directives from "vuetify/directives";

//font icon
// // import "@mdi/font/css/materialdesignicons.min.css";
// import { aliases, mdi } from "vuetify/iconsets/mdi";

// svg icon
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";

// import { md3 } from "vuetify/blueprints";

import theme from "./theme";
import defaults from "./defaults";
// import LuxonAdapter  from '@date-io/luxon'
import { id } from "vuetify/locale";

// import DateFnsAdapter from '@date-io/date-fns'
// import { id } from 'date-fns/locale'

// import { VuetifyDateAdapter } from 'vuetify/lib/composables/date/adapters/vuetify'

const vuetify = createVuetify({
    aliases: {
        VPage: VCard,
        VHr: VDivider,
        VIconSpin: VIcon,
        VDialogFull: VDialog,
    },
    defaults,
    // components,
    // directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    locale: {
        locale: "id",
        fallback: "sv",
        messages: { id },
    },
    // date: {
    //     adapter: VuetifyDateAdapter,
    // },
    // blueprint: md3,
    theme,
});

export default vuetify;
