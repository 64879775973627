import axios from "axios";
import { toastError } from "./mixins";

// let csrf = document
//     .querySelector('meta[name="csrf-token"]')
//     .getAttribute("content");
// console.log(csrf);

// axios.defaults.headers.common["X-CSRF-TOKEN"] = csrf;

async function refreshCsrfToken() {
    try {
        const response = await axios.get("/csrf-token");
        const token = response.data.token;
        // Update the meta tag
        document
            .querySelector('meta[name="csrf-token"]')
            .setAttribute("content", token);
        // Update Axios defaults
        // axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    } catch (error) {
        console.error("Error refreshing CSRF token:", error);
    }
}

const Api = axios.create({
    baseURL: window._asset + "api",
    // timeout: 10000,
    timeoutErrorMessage: "Request timeout",
});

Api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.message === "Request timeout") {
            toastError(error.message);
        }
        if (error.response && error.response.status === 419) {
            await refreshCsrfToken();
            // Retry the original request with the new token
            error.config.headers["X-CSRF-TOKEN"] = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content");
            return axios(error.config);
        }
        return Promise.reject(error);
    }
);

export default Api;
