<template>
    <v-dialog max-width="500" v-model="show">
        <v-card class="pt-6">
            <div class="px-6 font-bold text-lg">
                Cara menambahkan ke layar utama
            </div>
            <v-card-text class="pl-12 pt-5 pb-10">
                <ol>
                    <li>
                        <p>
                            Saat melihat situs web, ketuk
                            <img
                                :src="asset('images/misc/share-ios.png')"
                                alt="tombol Bagikan"
                                height="25"
                                width="20"
                                originalimagename="GlobalArt/IL_ShareBlue.png"
                                class="icon"
                            />
                            di bar menu.
                        </p>
                    </li>
                    <li class="mt-3">
                        <p>
                            Gulir ke bawah daftar pilihan, lalu ketuk Tambah ke
                            Layar Utama.
                        </p>
                        <p class="mt-3">
                            Jika Anda tidak melihat Tambah ke Layar Utama, Anda
                            dapat menambahkannya. Gulir ke bagian bawah daftar,
                            ketuk Edit&nbsp;Tindakan, lalu ketuk
                            <img
                                :src="asset('images/misc/plus-ios.png')"
                                alt=""
                                height="25"
                                width="25"
                                originalimagename="GlobalArt/IL_AddPlusGreen.png"
                                class="icon"
                            />
                            Tambah ke Layar Utama.
                        </p>
                    </li>
                </ol>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref } from "vue";
import { asset } from "@/mixins";
const show = ref(false);
function openDialog() {
    show.value = true;
}
defineExpose({
    openDialog,
});
</script>

<style scoped>
/* .cc ol li {
    margin: 0.25rem 0 0.25rem 1.25rem;
} */
li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}
ol {
    list-style-type: decimal;
}
.icon {
    display: inline;
    margin-inline: 0.5rem;
}
</style>
