<template>
    <div
        id="hScroll"
        class="flex gap-2 h-menu pl-5 pr-5 py-3 overflow-auto"
        :class="{
            'snap-mandatory scroll-pl-6 snap-x scroll-smooth': touchScreen,
        }"
        v-dragscroll.x="!touchScreen"
    >
        <template v-for="(m, idx) in menu">
            <v-btn
                :id="'goTo-' + (idx + 1)"
                @click="scrollTo('goTo-' + (idx + 1))"
                v-if="m.type == 'btn' && $can('read', m.access)"
                color="white"
                rounded
                :variant="$route.name != m.name ? 'text' : 'outlined'"
                :to="{ name: m.name }"
                class="text-capitalize snap-start"
            >
                <v-icon class="text-white" start>
                    <span :class="m.icon"></span>
                </v-icon>
                <div class="tracking-normal text-white">
                    {{ m.title }}
                </div>
            </v-btn>
            <v-menu
                v-if="m.type == 'dropdown' && $can('read', m.access)"
                transition="slide-y-transition"
                position="bottom"
                offset="22"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        @click="scrollTo('goTo-' + (idx + 1))"
                        :id="'goTo-' + (idx + 1)"
                        :variant="checkIfActive(m.list) ? 'outlined' : 'text'"
                        text
                        v-bind="props"
                        color="white"
                        rounded
                        class="text-capitalize snap-start"
                    >
                        <v-icon start :class="m.icon"></v-icon>
                        <div class="tracking-normal">
                            {{ m.title }}
                        </div>
                        <v-icon
                            end
                            class="icon-[tabler--chevron-down]"
                        ></v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <template v-for="l in m.list">
                        <v-list-item
                            link
                            :to="{ name: l.name }"
                            v-if="$can('read', l.access)"
                        >
                            <template v-slot:prepend>
                                <v-icon :class="l.icon"></v-icon>
                            </template>
                            <v-list-item-title>
                                {{ l.title }}</v-list-item-title
                            >
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>
<script setup>
const props = defineProps(["menu"]);
import { computed, onMounted, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { useGoTo } from "vuetify";

const goTo = useGoTo();
const options = reactive({
    container: "#hScroll",
    duration: 300,
    easing: "easeInOutCubic",
    offset: 0,
});

const route = useRoute();

function checkIfActive(list) {
    let find = list.find((e) => e.name == route.name);
    return find ? true : false;
}

const touchScreen = ref(false);
onMounted(() => {
    touchScreen.value = navigator.maxTouchPoints > 0;
});

function scrollTo(id) {
    // goTo.horizontal("#" + id, options);
}
</script>

<style lang="scss" scoped>
.bg-menu {
    background-color: #fbf5ff15;
    border-radius: 40px;
}

.h-menu {
    padding-left: 10px;
    overflow: auto;
}

.h-menu::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
.h-menu::-webkit-scrollbar-track {
    // background: rgba(20, 20, 20, 0);
    background: rgba(134, 134, 134, 0.349);
    border-radius: 10px;
}

/* Handle */
.h-menu::-webkit-scrollbar-thumb {
    background: #fcfafc;
    border-radius: 10px;
}

/* Handle on hover */
.h-menu::-webkit-scrollbar-thumb:hover {
    background: #fcfafc;
}
// background: #00a2ff;
</style>
