<template>
    <div class="boxed-navbar px-5">
        <div class="flex align-center">
            <v-avatar color="white" size="60" rounded>
                <v-img :src="asset('android-chrome-512x512.png')" />
            </v-avatar>
            <!-- v-if="$vuetify.display.width > 393" -->
            <div class="ml-1" v-if="$vuetify.display.width > 350">
                <div class="font-bold text-[13pt] sm:text-xl">Mentari</div>
                <div class="text-[9pt] sm:text-sm -mt-1">RSUD Cibinong</div>
            </div>
        </div>
        <!-- <v-toolbar-title>Vuetify</v-toolbar-title> -->
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition leave-absolute>
            <HorizontalMenu
                v-if="$route.meta.layout == 'LayoutUser'"
                :menu="menu"
            ></HorizontalMenu>
        </v-slide-x-reverse-transition>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
            <v-icon
                v-if="$vuetify.display.mdAndDown"
                @click="openDrawer"
                class="text-primary mr-4"
                start
            >
                <span
                    :key="key"
                    v-if="drawer"
                    class="icon-[line-md--menu-to-close-alt-transition]"
                ></span>
                <span
                    :key="key"
                    v-if="!drawer"
                    class="icon-[line-md--close-to-menu-alt-transition]"
                ></span>
            </v-icon>
            <!-- <v-app-bar-nav-icon
                @click="openDrawer"
                class="text-white"
                v-if="$vuetify.display.width <= 1160"
            ></v-app-bar-nav-icon> -->
        </v-slide-x-reverse-transition>
        <!-- <template v-if="$vuetify.display.smAndUp"> -->
        <!-- <ThemeSwitcher></ThemeSwitcher> -->
        <!-- </template> -->
        <template v-if="$can('read', 'auth')">
            <AppBarUserMenu></AppBarUserMenu>
        </template>
        <template v-else>
            <v-btn
                v-if="$vuetify.display.lgAndUp"
                color="primary"
                variant="text"
                class="mr-2"
                :to="{ name: 'register' }"
                >Register</v-btn
            >
            <v-btn color="primary" variant="elevated" :to="{ name: 'login' }">
                Konsultasi
                <span class="ml-1" v-if="$vuetify.display.mdAndUp"
                    >Sekarang</span
                >
            </v-btn>
        </template>
    </div>
</template>
<script setup>
// import menu from "@/menu.js";
import ThemeSwitcher from "./component/ThemeSwitcher.vue";
import HorizontalMenu from "./component/HorizontalMenu.vue";
import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import { asset } from "@/mixins";
import { ref, watch } from "vue";

const props = defineProps(["drawer", "menu", "countMenu"]);
const emit = defineEmits(["emitDrawer"]);
const openDrawer = () => {
    emit("emitDrawer");
    key.value += 1;
};

const key = ref(false);
watch(
    () => props.drawer,
    () => {
        key.value += 1;
    }
);
</script>
